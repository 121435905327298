import logo from './logo.svg';
import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import i18n from './i18n.json';


function App() {
  let weeks = ["+0", "+1", "+2", "+3", "+4", "+5", "+6", "+7"];
  const [value, onChange] = useState(new Date());
  const [opac, setOpac] = useState(0);

  const [foglalas, setFoglalas] = useState(false);
  const [loaddates, setLoaddates] = useState(false);

  const [logedInUser, setLogedInUser] = useState({});

  const [myapps, setMyapps] = useState([]);

  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);

  const [language, setLanguage] = useState("hu");
  const [pos, setPos] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showmobile, setShowmobile] = useState(false);
  const [times, setTimes] = useState([]);
  const [dates, setDates] = useState([]);
  const [items, setItems] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const [services, setServices] = useState([])
  const [barbers, setBarbers] = useState([]);
  const [abarbers, setAbarbers] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [login, setLogin] = useState(true);
  const [lostpw, setLostpw] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");

  const handleName = useCallback(event => {
    setName(event.target.value)
  })

  const [galery, setGalery] = useState([]);

  useEffect(() => {

    const timer = setTimeout(() => {
      document.body.style.background = "#333333";
      var metaThemeColor = document.querySelector("meta[name=theme-color]");
      metaThemeColor.setAttribute("content", "#333333");
      setLoaded(true);
    }, 2400);

    const timer2 = setTimeout(() => {
      setOpac(1)
    }, 3000);
    //}, 200000000);

    fetch("https://menstage.hu/mapi/index.php/api/service").then(res => res.json()).then(result =>
      setServices(result)
    ).catch(console.log);

    fetch("https://menstage.hu/mapi/index.php/api/gallery").then(res => res.json()).then(result =>
      setGalery(result)
    ).catch(console.log);

    fetch("https://menstage.hu/mapi/index.php/api/").then(res => res.json()).then(result =>
      setBarbers(result)
    ).catch(console.log);
  }, [])

  var a = 0;

  const Foglalas = props => {
    switch (pos) {
      case 6: return (
        <div>
          <h2><span>{i18n[language].success}</span></h2>
          <div style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}>
            {
              logedInUser.myapps.length !== 0 ? (
              <div>
            <h3 style={{ color: "#fff"}}>{i18n[language].alreadyhas}</h3>
            <div>
              {logedInUser.myapps.map((app) => (
                <p  style={{ color: "#fff"}}>{app.date + " " + app.time }</p>
              ))}
            </div>
            <p  style={{ color: "#fff"}}>{i18n[language].areusure}</p>
            <br/>
            </div>) : (<span />) }
            <a className='back_button' onClick={() => {
              fetch("https://menstage.hu/mapi/index.php/api/times?id=" + selectedValues.id + "&date=" + selectedValues.date + "&service=" + selectedValues.service).then(res => res.json()).then(result =>
                setTimes(result)
              ).catch(console.log);
              setPos(3)
            }}>{i18n[language].back}</a>
            <a className='next_button' onClick={() => {
              if (selectedValues.date !== undefined) {
                if (!foglalas) {
                  setFoglalas(true)
                  fetch("https://menstage.hu/mapi/index.php/api/foglalas?name="
                    + name + "&phone=" + phone + "&userid=" + logedInUser.userid
                    + "&date=" + selectedValues.date + "&time=" + selectedValues.time
                    + "&barber=" + selectedValues.id + "&service=" + selectedValues.service + "&lng=" + language
                  ).then(res => res.json()).then(result => {
                    if (result.result == "ok") {
                      window.scrollTo(0, 0)
                      setFoglalas(false)
                      setSuccess(true);
                      const timer = setTimeout(() => {
                        document.body.style.background = "#333333";
                        var metaThemeColor = document.querySelector("meta[name=theme-color]");
                        metaThemeColor.setAttribute("content", "#333333");
                        setSuccess(false);
                        setPos(0)
                      }, 3000);
                    } else {
                      if (language == "hu") {
                        alert("Sajnos ez az időpont már nem elérhető!");
                      } else {
                        alert("Unfortunately, this appointment is no longer available!");
                      }
                    }
                  }
                  ).catch(res => {
                    if (language == "hu") {
                      alert("Sajnos ez az időpont már nem elérhető!");
                    } else {
                      alert("Unfortunately, this appointment is no longer available!");
                    }
                    setFoglalas(false)
                  });
                }
              }
            }}>{i18n[language].finalize}</a>
          </div>
          {
            foglalas ? <div class="loader"></div> : <span />
          }
          <p style={{ fontSize: "16pt", color: "#fff", fontWeight: "bold", textAlign: "center" }}>{selectedValues.barbername}<br />{selectedValues.date}<br />{i18n[language]['day' + (new Date(selectedValues.date).getDay())]} {selectedValues.time}<br />{services.filter(s => s.id == selectedValues.service).reduce((r, i) => i["name_" + language] + " " + (selectedValues.custom_price[selectedValues.service] ? selectedValues.custom_price[selectedValues.service] : (selectedValues.master == 1 ? i.price2 : i.price1)), "")}</p>
          <p style={{ fontSize: "14pt", color: "#fff", textAlign: "center" }}>{i18n[language].bookextrainfo}</p>
        </div>
      )
      case 2:
        return (
          <div key="datum">
            <h2><span>{i18n[language].date}</span></h2>
            <div style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}>
              <a className='back_button' onClick={() => {
                setPos(1)
              }}>{i18n[language].back}</a>
              <a className='next_button' onClick={() => {
                if (selectedValues.date !== undefined) {
                  fetch("https://menstage.hu/mapi/index.php/api/times?id=" + selectedValues.id + "&date=" + selectedValues.date + "&service=" + selectedValues.service).then(res => res.json()).then(result =>
                    setTimes(result)
                  ).catch(console.log);
                  setPos(3)
                }
              }}>{i18n[language].next}</a>
            </div>
            <div className='calendar-helper'>
              <div className='cal-men'>
                <div className='cal-row cal-head'>
                  <div className='cal-entry'>{i18n[language].monday}</div>
                  <div className='cal-entry'>{i18n[language].tuesday}</div>
                  <div className='cal-entry'>{i18n[language].wednesday}</div>
                  <div className='cal-entry'>{i18n[language].thursday}</div>
                  <div className='cal-entry'>{i18n[language].friday}</div>
                  <div className='cal-entry'>{i18n[language].saturday}</div>
                  <div className='cal-entry'>{i18n[language].sunday}</div>
                </div>
                <div style={{ marginTop: 5, color: "#fff", textAlign: 'center' }}>{
                  i18n[language]["month" + (a = dates['+0'][6].date.split('-')[1])]
                }</div>
                <div className='cal-row'>
                  {
                    dates['+0']?.map((dt) => (
                      <div key={dt.date} onClick={() => {
                        if (!dt.old && dt.active == 1) {
                          var tmp = selectedValues;
                          tmp['date'] = dt.date;
                          setSelectedValues(tmp)
                          fetch("https://menstage.hu/mapi/index.php/api/times?id=" + selectedValues.id + "&date=" + dt.date + "&service=" + selectedValues.service).then(res => res.json()).then(result =>
                            setTimes(result)
                          ).catch(console.log);
                          setPos(3)
                        }
                      }}
                        className={"cal-entry " + (selectedValues.date === dt.date ? " selected " : "") + (dt.old ? "old" : "") + (dt.active == 1 ? "active" : "inactive")}>{dt.day}</div>
                    ))
                  }
                </div>
                <div style={{ color: "#fff", textAlign: 'center' }}>{
                  a !== dates['+1'][6].date.split('-')[1] ? i18n[language]["month" + (a = dates['+1'][6].date.split('-')[1])] : ""
                }</div>
                <div className='cal-row'>
                  {
                    dates['+1']?.map((dt) => (
                      <div key={dt.date} onClick={() => {
                        if (!dt.old && dt.active == 1) {
                          var tmp = selectedValues;
                          tmp['date'] = dt.date;
                          setSelectedValues(tmp)
                          fetch("https://menstage.hu/mapi/index.php/api/times?id=" + selectedValues.id + "&date=" + dt.date + "&service=" + selectedValues.service).then(res => res.json()).then(result =>
                            setTimes(result)
                          ).catch(console.log);
                          setPos(3)
                        }
                      }}
                        className={"cal-entry " + (selectedValues.date === dt.date ? " selected " : "") + (dt.old ? "old" : "") + (dt.active == 1 ? "active" : "inactive")}>{dt.day}</div>
                    ))
                  }
                </div>
                <div style={{ color: "#fff", textAlign: 'center' }}>{
                  a !== dates['+2'][6].date.split('-')[1] ? i18n[language]["month" + (a = dates['+2'][6].date.split('-')[1])] : ""
                }</div>
                <div className='cal-row'>
                  {
                    dates['+2']?.map((dt) => (
                      <div key={dt.date} onClick={() => {
                        if (!dt.old && dt.active == 1) {
                          var tmp = selectedValues;
                          tmp['date'] = dt.date;
                          setSelectedValues(tmp)
                          fetch("https://menstage.hu/mapi/index.php/api/times?id=" + selectedValues.id + "&date=" + dt.date + "&service=" + selectedValues.service).then(res => res.json()).then(result =>
                            setTimes(result)
                          ).catch(console.log);
                          setPos(3)
                        }
                      }}
                        className={"cal-entry " + (selectedValues.date === dt.date ? " selected " : "") + (dt.old ? "old" : "") + (dt.active == 1 ? "active" : "inactive")}>{dt.day}</div>
                    ))
                  }
                </div>
                <div style={{ color: "#fff", textAlign: 'center' }}>{
                  a !== dates['+3'][6].date.split('-')[1] ? i18n[language]["month" + (a = dates['+3'][6].date.split('-')[1])] : ""
                }</div>
                <div className='cal-row'>
                  {
                    dates['+3']?.map((dt) => (
                      <div key={dt.date} onClick={() => {
                        if (!dt.old && dt.active == 1) {
                          var tmp = selectedValues;
                          tmp['date'] = dt.date;
                          setSelectedValues(tmp)
                          fetch("https://menstage.hu/mapi/index.php/api/times?id=" + selectedValues.id + "&date=" + dt.date + "&service=" + selectedValues.service).then(res => res.json()).then(result =>
                            setTimes(result)
                          ).catch(console.log);
                          setPos(3)
                        }
                      }}
                        className={"cal-entry " + (selectedValues.date === dt.date ? " selected " : "") + (dt.old ? "old" : "") + (dt.active == 1 ? "active" : "inactive")}>{dt.day}</div>
                    ))
                  }
                </div>
                <div style={{ color: "#fff", textAlign: 'center' }}>{
                  a !== dates['+4'][6].date.split('-')[1] ? i18n[language]["month" + (a = dates['+4'][6].date.split('-')[1])] : ""
                }</div>
                <div className='cal-row'>
                  {
                    dates['+4']?.map((dt) => (
                      <div key={dt.date} onClick={() => {
                        if (!dt.old && dt.active == 1) {
                          var tmp = selectedValues;
                          tmp['date'] = dt.date;
                          setSelectedValues(tmp)
                          fetch("https://menstage.hu/mapi/index.php/api/times?id=" + selectedValues.id + "&date=" + dt.date + "&service=" + selectedValues.service).then(res => res.json()).then(result =>
                            setTimes(result)
                          ).catch(console.log);
                          setPos(3)
                        }
                      }}
                        className={"cal-entry " + (selectedValues.date === dt.date ? " selected " : "") + (dt.old ? "old" : "") + (dt.active == 1 ? "active" : "inactive")}>{dt.day}</div>
                    ))
                  }
                </div>
                <div style={{ color: "#fff", textAlign: 'center' }}>{
                  a !== dates['+5'][6].date.split('-')[1] ? i18n[language]["month" + (a = dates['+5'][6].date.split('-')[1])] : ""
                }</div>
                <div className='cal-row'>
                  {
                    dates['+5']?.map((dt) => (
                      <div key={dt.date} onClick={() => {
                        if (!dt.old && dt.active == 1) {
                          var tmp = selectedValues;
                          tmp['date'] = dt.date;
                          setSelectedValues(tmp)
                          fetch("https://menstage.hu/mapi/index.php/api/times?id=" + selectedValues.id + "&date=" + dt.date + "&service=" + selectedValues.service).then(res => res.json()).then(result =>
                            setTimes(result)
                          ).catch(console.log);
                          setPos(3)
                        }
                      }}
                        className={"cal-entry " + (selectedValues.date === dt.date ? " selected " : "") + (dt.old ? "old" : "") + (dt.active == 1 ? "active" : "inactive")}>{dt.day}</div>
                    ))
                  }
                </div>
                <div style={{ color: "#fff", textAlign: 'center' }}>{
                  a !== dates['+6'][6].date.split('-')[1] ? i18n[language]["month" + (a = dates['+6'][6].date.split('-')[1])] : ""
                }</div>
                <div className='cal-row'>
                  {
                    dates['+6']?.map((dt) => (
                      <div key={dt.date} onClick={() => {
                        if (!dt.old && dt.active == 1) {
                          var tmp = selectedValues;
                          tmp['date'] = dt.date;
                          setSelectedValues(tmp)
                          fetch("https://menstage.hu/mapi/index.php/api/times?id=" + selectedValues.id + "&date=" + dt.date + "&service=" + selectedValues.service).then(res => res.json()).then(result =>
                            setTimes(result)
                          ).catch(console.log);
                          setPos(3)
                        }
                      }}
                        className={"cal-entry " + (selectedValues.date === dt.date ? " selected " : "") + (dt.old ? "old" : "") + (dt.active == 1 ? "active" : "inactive")}>{dt.day}</div>
                    ))
                  }
                </div>
                <div style={{ color: "#fff", textAlign: 'center' }}>{
                  a !== dates['+7'][6].date.split('-')[1] ? i18n[language]["month" + (a = dates['+7'][6].date.split('-')[1])] : ""
                }</div>
                <div className='cal-row'>
                  {
                    dates['+7']?.map((dt) => (
                      <div key={dt.date} onClick={() => {
                        if (!dt.old && dt.active == 1) {
                          var tmp = selectedValues;
                          tmp['date'] = dt.date;
                          setSelectedValues(tmp)
                          fetch("https://menstage.hu/mapi/index.php/api/times?id=" + selectedValues.id + "&date=" + dt.date + "&service=" + selectedValues.service).then(res => res.json()).then(result =>
                            setTimes(result)
                          ).catch(console.log);
                          setPos(3)
                        }
                      }}
                        className={"cal-entry " + (selectedValues.date === dt.date ? " selected " : "") + (dt.old ? "old" : "") + (dt.active == 1 ? "active" : "inactive")}>{dt.day}</div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        )
      case 0:
        return (
          <div>
            <h2><span>{i18n[language].barbers}</span></h2>

            <OwlCarousel items={1} responsive={{ 0: { items: 1 }, 800: { items: 3 }, 1200: { items: 5 } }} stagePadding={70} className="owl-theme" loop={false} rewind={true} margin={10} nav={true}>
              {
                barbers.map((barber, index) => {
                  return (
                    <a key={barber.id} onClick={() => {
                      setPos(1)
                      setSelectedValues({ "id": barber.id, "master": barber.master, "services": barber.services, "custom_price": barber.custom_price == "" ? {} : JSON.parse(barber.custom_price) });
                    }}>
                      <div className='item barber'>
                        <img className={index == 0 ? "early" : ""} src={barber.image} />
                        <h4 style={{ marginBottom: 0}}><span>{index == 0 && language == "en" ? "Earliest date" : barber.name}</span></h4>
                        { barber.master == 1 ? <p style={{ display: "inline-block", margin: 5, padding: "5px 10px", fontWeight: "bold", background: "#141414"}}>{i18n[language].master}</p> : <p style={{ height: 34, margin: 0, padding: 0}}></p> }
                        <p style={{ margin: 0 }}>{barber.phone}</p>
                      </div>
                    </a>
                  )
                })
              }
            </OwlCarousel>
          </div>
        )
      case 10:
        return (
          <div>
            <h2><span>{i18n[language].barbers2}</span></h2>
            <div style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}>
              <a className='back_button' onClick={() => {
                setPos(3)
              }}>{i18n[language].back}</a></div>
            <OwlCarousel callbacks={true} items={1} responsive={{ 0: { items: 1, loop: true }, 800: { items: 3, loop: true }, 1200: { items: 5, loop: true } }} stagePadding={70} className="owl-theme" loop={true} margin={10} nav={false}>
              {
                abarbers.map((barber, index) => {
                  return (
                    <div key={barber.id} className='item barber' onClick={() => {
                      var tmp = selectedValues;
                      tmp['id'] = barber.id;
                      tmp['barbername'] = barber.name;
                      tmp['master'] = barber.master;
                      tmp['services'] = barber.services;
                      tmp['custom_price'] = barber.custom_price == "" ? {} : JSON.parse(barber.custom_price)
                      setSelectedValues(tmp)
                      if (selectedValues.time !== undefined) {
                        if (logedInUser.userid == undefined) {
                          setPos(4)
                        } else {
                          setPos(6)
                        }
                      }
                    }}>
                      <img src={barber.image} />
                      <h4 style={{ marginBottom: 0}}><span>{index == 0 && language == "en" ? "Earliest date" : barber.name}</span></h4>
                      { barber.master == 1 ? <p style={{ display: "inline-block", margin: 5, padding: "5px 10px", fontWeight: "bold", background: "#141414"}}>{i18n[language].master}</p> : <p style={{ height: 34, margin: 0, padding: 0}}></p> }
                      <p style={{ margin: 0 }}>{barber.phone}</p>

                    </div>
                  )
                })
              }
            </OwlCarousel>
          </div>
        )
      case 1:
        return (
          <div>
            <h2><span>{i18n[language].services}</span></h2>
            <div style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}>
              <a className='back_button' onClick={() => {
                setPos(0)
              }}>{i18n[language].back}</a>
              <a className='next_button' onClick={() => {
                if (loaddates || selectedValues.service !== undefined) {
                  setLoaddates(true)
                  fetch("https://menstage.hu/mapi/index.php/api/dates?id=" + selectedValues.id + "&service=" + selectedValues.service).then(res => res.json()).then(result => {
                    setLoaddates(false)
                    setDates(result)
                    setPos(2)
                  }
                  ).catch(console.log);
                }
              }}>{i18n[language].next}</a>
            </div>
            <div className='service-helper'>
              {
                loaddates ? <div class="loader"></div> : <span />
              }
              {
                services.filter(s => selectedValues.services.split('-').includes(s.id)).map((service) => {
                  return (
                    <div key={service.id} className={"service " + (selectedValues.service === service.id ? " selected " : "")} onClick={() => {
                      if (!loaddates) {
                        var tmp = selectedValues;
                        tmp['service'] = service.id;
                        //tmp['custom_price'] = selectedValues.custom_price[service.id] ? selectedValues.custom_price[service.id] : "";
                        setSelectedValues(tmp)
                        setLoaddates(true)
                        fetch("https://menstage.hu/mapi/index.php/api/dates?id=" + selectedValues.id + "&service=" + service.id).then(res => res.json()).then(result => {
                          setLoaddates(false)
                          setDates(result)
                          setPos(2)
                        }
                        ).catch(console.log);
                      }
                    }}>
                      <h4><span>{service["name_" + language]} - {service.time} perc</span></h4>
                      {
                        selectedValues.id == 1 ?
                        <p style={{ color: "#fff", display: "inline-block", marginTop: 10, padding: "2px 4px", fontSize: 12, background: "#141414"}}>{
                          "Normál: " + service.price1 + "    Mester: " + service.price2
                          }</p>
                        :
                        <p>{ selectedValues.custom_price[service.id] ? selectedValues.custom_price[service.id] : (selectedValues.master == 1 ? service.price2 : service.price1) }</p>
                      }  

                    </div>
                  )
                })
              }
            </div>
          </div>
        )
      case 3:
        return (
          <div>
            <h2><span>{i18n[language].time}</span></h2>
            <p style={{ color: "#fff", textAlign: "center", fontSize: 20 }}>{ selectedValues.date }</p>
            <div style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}>
              <a className='back_button' onClick={() => {
                setPos(2)
              }}>{i18n[language].back}</a>
              <a className='next_button' onClick={() => {
                if (selectedValues.id == 1) {
                  fetch("https://menstage.hu/mapi/index.php/api/getavailablebarbers?time=" + selectedValues.time + "&id=" + selectedValues.id + "&date=" + selectedValues.date + "&service=" + selectedValues.service).then(res => res.json()).then(result => {
                    setAbarbers(result)
                    setPos(10)
                  }
                  ).catch(console.log);
                } else {
                  fetch("https://menstage.hu/mapi/index.php/api/getbarber?time=" + selectedValues.time + "&id=" + selectedValues.id + "&date=" + selectedValues.date).then(res => res.json()).then(result => {
                    var tmp = selectedValues;
                    tmp['id'] = result.id;
                    tmp['barbername'] = result.barbername;
                    setSelectedValues(tmp)
                  }
                  ).catch(console.log);
                  if (selectedValues.time !== undefined) {
                    if (logedInUser.userid == undefined) {
                      setPos(4)
                    } else {
                      setPos(6)
                    }
                  }
                }
              }}>{i18n[language].next}</a>
            </div>
            <div className='time-helper'>
              {
                times.map((service) => {
                  return (
                    <div key={service.time} className={'time ' + (service.free ? "" : "not-free")} onClick={() => {
                      if (service.free) {
                        var tmp = selectedValues;
                        tmp['time'] = service.time;
                        setSelectedValues(tmp)
                        if (selectedValues.id == 1) {
                          fetch("https://menstage.hu/mapi/index.php/api/getavailablebarbers?time=" + selectedValues.time + "&id=" + selectedValues.id + "&date=" + selectedValues.date + "&service=" + selectedValues.service).then(res => res.json()).then(result => {
                            setAbarbers(result)
                            setPos(10)
                          }
                          ).catch(console.log);
                        } else {
                          fetch("https://menstage.hu/mapi/index.php/api/getbarber?time=" + selectedValues.time + "&id=" + selectedValues.id + "&date=" + selectedValues.date).then(res => res.json()).then(result => {
                            var tmp = selectedValues;
                            tmp['id'] = result.id;
                            tmp['barbername'] = result.barbername;
                            setSelectedValues(tmp)
                            if (logedInUser.userid == undefined) {
                              setPos(4)
                            } else {
                              setPos(6)
                            }
                          }
                          ).catch(console.log);
                        }
                      }
                    }}>
                      <h4><span>{service.time}</span></h4>
                    </div>
                  )
                })
              }
            </div>
          </div>
        )
      case 4:
        return (
          <div key="vegleges" id="vegleges">
          </div>
        )
      default:
        return (
          <div className='calendar-helper'>
            asd
          </div>
        )
    }
  }

  if (!loaded) {
    return (
      <div style={{ justifyContent: "center", width: '100vw', height: '100vh', display: "flex", alignItems: "center" }}>
        <div className="loadanim" style={{ paddingTop: 200, fontSize: "24pt", fontWeight: "bold", color: "#fff" }}>
          <span className='anim1'>M</span>
          <span className='anim2'>E</span>
          <span className='anim3'>N</span>
          <span className='anim4'>S</span>
          <span className='anim5'>T</span>
          <span className='anim6'>A</span>
          <span className='anim7'>G</span>
          <span className='anim8'>E</span>
          <div style={{ position: "relative", borderTop: "6px solid #000", background: "#fff", height: 300 }}></div>
        </div>
      </div>
    )
  } else if (success) {
    return (
      <div style={{ justifyContent: "center", width: '100vw', height: '100vh', display: "flex", alignItems: "center" }}>
        <div className="loadanim2" style={{ width: '50vw', fontSize: "20pt", fontWeight: "bold", color: "#fff" }}>
          <span className="" style={{ display: "block", color: "#000", textAlign: "center" }}>
            {
              i18n[language].final
            }
          </span>
        </div>
      </div>)
  } else {
    return (
      <div style={{ background: "#333", position: "relative" }}>
        <div className='menu' style={{ position: "fixed", width: "100%", zIndex: 1000, height: 55, background: "rgb(51 51 51 / 87%)" }}>
          <div className='showmobile'>
            <a className="fmobile" href="/#"><img style={{ height: 22, padding: 18 }} src="/img/logo_preload.png" alt="" /></a>

            <a className="lmobile" onClick={() => {
              setLanguage(language == "hu" ? "en" : "hu");
            }} style={{ color: "#fff", textDecoration: "none" }}>
              <img style={{ height: 15, padding: 20 }} src={"/img/" + (language == "hu" ? "eng" : "hun") + ".png"} alt="" />
            </a>

            <a className="smobile" onClick={() => {
              setShowmobile(!showmobile)
            }}><img style={{ width: 34, marginRight: 20 }} src="/img/hamburger.png" /></a>
          </div>

          <div style={{ flexDirection: "column", display: !showmobile ? "none" : "flex", background: "rgb(51 51 51 / 87%)" }}>
            <div style={{ width: "100%", color: "#fff", padding: 10 }}>
              <a onClick={() => {
                setShowmobile(!showmobile)
              }} href="/#foglalas" style={{ color: "#fff", textDecoration: "none" }}>
                {i18n[language].booking}
              </a>
            </div>

            <div style={{ width: "100%", color: "#fff", padding: 10 }}>
              <a onClick={() => {
                setShowmobile(!showmobile)
              }} href="/#foglalasaim" style={{ color: "#fff", textDecoration: "none" }}>
                {i18n[language].reservedtime}
              </a>
            </div>

            <div style={{ width: "100%", color: "#fff", padding: 10 }}>
              <a onClick={() => {
                setShowmobile(!showmobile)
              }} href="/#arlista" style={{ color: "#fff", textDecoration: "none" }}>
                {i18n[language].prices}
              </a>
            </div>

            <div style={{ width: "100%", color: "#fff", padding: 10 }}>
              <a onClick={() => {
                setShowmobile(!showmobile)
              }} href="/#bemutatkozas" style={{ color: "#fff", textDecoration: "none" }}>
                {i18n[language].aboutus}
              </a>
            </div>

            <div style={{ width: "100%", color: "#fff", padding: 10 }}>
              <a onClick={() => {
                setShowmobile(!showmobile)
              }} href="/#galery" style={{ color: "#fff", textDecoration: "none" }}>
                {i18n[language].gallery}

              </a>
            </div>

            <div style={{ width: "100%", color: "#fff", padding: 10 }}>
              <a onClick={() => {
                setShowmobile(!showmobile)
              }} href="/#kapcsolat" style={{ color: "#fff", textDecoration: "none" }}>
                {i18n[language].contact}
              </a>
            </div>
          </div>


          <ul className='hidemobile'>
            <li>
              <a href="/#"><img style={{ height: 25, padding: 15 }} src="/img/logo_preload.png" alt="" /></a>
            </li>
            <li>
              <a href="/#foglalas">
                {i18n[language].booking}
              </a>
            </li>
            <li>
              <a href="/#foglalasaim">
                {i18n[language].reservedtime}
              </a>
            </li>
            <li>
              <a href="/#fodrasz">
                {i18n[language].barbers}
              </a>
            </li>
            <li>
              <a href="/#arlista">
                {i18n[language].prices}
              </a>
            </li>
            <li>
              <a href="/#bemutatkozas">
                {i18n[language].aboutus}
              </a>
            </li>
            <li>
              <a href="/#galery">
                {i18n[language].gallery}
              </a>
            </li>
            <li>
              <a href="/#kapcsolat">
                {i18n[language].contact}
              </a>
            </li>
            <li>
              <a className="lmobile" onClick={() => {
                setLanguage(language == "hu" ? "en" : "hu");
              }} style={{ color: "#fff", textDecoration: "none" }}>
                <img style={{ height: 15, padding: 20 }} src={"/img/" + (language == "hu" ? "eng" : "hun") + ".png"} alt="" />
              </a>
            </li>
          </ul>
        </div>
        <video playsInline={true} autoPlay={true} muted={true} loop={true} id="bgvid">
          
          <source src="/video/main.mp4" type="video/mp4" />
          <source src="/video/main.webm" type="video/webm" />
  
        </video>
        <header className="viewport-header">
          <div className='inner-2'>
            <p>
              <a href="/#foglalas">
                {i18n[language].booking}
              </a>
            </p>
          </div>
        </header>
        <div id="foglalas" style={{ paddingTop: 30 }} key="foglalas" className='fullpage'>
          <div style={{ marginTop: 55 }}><span style={{ color: "#333" }}>_</span></div>
          {
            pos == 4 ?
              (<div key="vegleges" id="vegleges">
                <h2><span>{i18n[language].booking}!</span></h2>
                <div style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}>
                  <a className='back_button' onClick={() => {
                    setPos(3)
                  }}>{i18n[language].back}</a>
                </div>
                {
                  login ? (
                    <div>
                      <p className='form'>
                        <span>
                          {i18n[language].email}
                        </span>
                        <input id="email" key="email" type="email" name="email" value={email} onChange={(event) => setEmail(event.target.value)} />
                      </p>
                      <p className='form' style={{ display: lostpw ? "none" : "block" }}>
                        <span>
                          {i18n[language].password}
                        </span>
                        <input id="password" key="password" type="password" name="password" value={password} onChange={(event) => setPassword(event.target.value)} />
                      </p>
                    </div>
                  ) : (
                    <form key="regform">
                      <p className='form'>
                        <span>
                          {i18n[language].name}
                        </span>
                        <input id="name" key="name" type="text" name="name" value={name} onChange={handleName} />
                      </p>
                      <p className='form'>
                        <span>
                          {i18n[language].phone}
                        </span>
                        <input id="phone" key="phone" type="text" name="phone" value={phone} onChange={(event) => setPhone(event.target.value)} />
                      </p>
                      <p className='form'>
                        <span>
                          {i18n[language].email}
                        </span>
                        <input id="email" key="email" type="email" name="email" value={email} onChange={(event) => setEmail(event.target.value)} />
                      </p>
                      <p className='form'>
                        <span>
                          {i18n[language].password}
                        </span>
                        <input id="password" key="password" type="password" name="password" value={password} onChange={(event) => setPassword(event.target.value)} />
                      </p>
                    </form>
                  )
                }
                <p className='form' onClick={() => {
                  if (lostpw) {
                    if (email == "") {
                      alert(i18n[language].emailerror);
                    } else {
                      fetch("https://menstage.hu/mapi/index.php/api/lostpw?email=" + email).then(res => res.json()).then(result => {
                        setLogin(true)
                        setLostpw(false)
                        alert(i18n[language].lostpwemail);
                      }
                      ).catch(res => {
                        alert("Ismeretlen e-mail cím");
                      });
                    }
                  }
                  else {
                    if (login) {
                      console.log(name);
                      if (email == "") {
                        alert(i18n[language].emailerror);
                      } else if (password.length < 6) {
                        alert(i18n[language].pwderror);
                      } else {
                        fetch("https://menstage.hu/mapi/index.php/api/login?name="
                          + name + "&phone=" + phone + "&email=" + email + "&password=" + password
                          + "&date=" + selectedValues.date + "&time=" + selectedValues.time
                          + "&barber=" + selectedValues.id + "&service=" + selectedValues.service
                        ).then(res => res.json()).then(result => {
                          setLogedInUser(result)
                          setPos(6)
                        }
                        ).catch(res => {
                          alert("Az e-mail jelszó páros nem megfelelő!");
                        });
                      }
                    } else {
                      setLogin(true)
                    }
                  }
                }}>
                  <button>{lostpw ? i18n[language].lostpw : i18n[language].login}</button>
                </p>
                <p className='form' onClick={() => {
                  if (lostpw) {
                    setLostpw(false);
                  } else {
                    if (!login) {
                      if (email == "") {
                        alert(i18n[language].emailerror);
                      } else if (password.length < 6) {
                        alert(i18n[language].pwderror);
                      } else if (name.trim() == "") {
                        alert(i18n[language].namerror);
                      } else if (phone.trim() == "") {
                        alert(i18n[language].phonerror);
                      } else {
                        fetch("https://menstage.hu/mapi/index.php/api/registration?name="
                          + name + "&phone=" + phone + "&email=" + email + "&password=" + password
                          + "&date=" + selectedValues.date + "&time=" + selectedValues.time
                          + "&barber=" + selectedValues.id + "&service=" + selectedValues.service
                        ).then(res => res.json()).then(result => {
                          setLogedInUser(result)
                          setPos(6)
                        }
                        ).catch(res => {
                          alert("Az e-mail cím már foglalt, próbáljon meg bejelentkezni");
                        });
                      }
                    } else {
                      setLogin(false)
                    }
                  }
                }}>
                  <button>{lostpw ? i18n[language].back : i18n[language].registration}</button>
                </p>
                <p style={{ display: lostpw ? "none" : "block", paddingTop: 10, color: "#fff", textAlign: "center" }}>
                  <a onClick={() => { setLogin(true); setLostpw(true); }} style={{ color: "#fff", textAlign: "center", cursor: "pointer" }}>{i18n[language].lostpw}</a>
                </p>
              </div>) :
              (<Foglalas key={"foglalas_key" + pos} id="foglalas_key"></Foglalas>)
          }
        </div>
        <div id="fodrasz" style={{ display: "none" }} className='fullpage'>
          <h2><span>{i18n[language].barbers}</span></h2>
          <div className='calendar-helper'>
            {
              barbers.map((barber, index) => {
                if (index != 0) {
                  return (
                    <div key={"fod_" + barber.id} className='barber' onClick={() => {
                      setPos(2)
                    }}>
                      <img src={barber.image} />
                      <h4><span>{barber.name}</span></h4>
                      <p>{barber.phone}</p>
                    </div>
                  )
                }
              })
            }
          </div>
        </div>

        <div id="arlista" style={{ paddingTop: 30 }} className=''>
          <div style={{ marginTop: 55 }}><span style={{ color: "#333" }}>_</span></div>
          <div className="arlistabg" style={{ padding: 20, margin: 10, marginBottom: 20, display: "flex", flexDirection: "column" }}>
            <h2><span style={{ color: "#000", marginTop: 10 }}>{i18n[language].prices}</span></h2>
            <div style={{ borderBottom: "1px solid #000", display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ fontSize: "14pt", fontWeight: "bold", width: "50%" }}></div>
                <div style={{ paddingTop: 20, paddingBottom: 20, borderLeft: "1px solid #000", borderRight: "1px solid #000", fontSize: "14pt", fontWeight: "bold", width: "50%", textAlign: "center" }}>{i18n[language].normal}</div>
                <div style={{ paddingTop: 20, paddingBottom: 20, fontSize: "14pt", fontWeight: "bold", width: "50%", textAlign: "center" }}>{i18n[language].master}</div>
              </div>
            </div>
            {
              services.map((s, index) => {
                return (
                  <div key={"serv" + s.id} style={{ borderBottom: index == services.length - 1 ? 'none' : "1px solid #000", display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ paddingTop: 20, paddingBottom: 20, fontSize: "14pt", fontWeight: "bold", width: "50%" }}>{s["name_" + language]}</div>
                      <div style={{ paddingTop: 20, paddingBottom: 20, borderLeft: "1px solid #000", borderRight: "1px solid #000", textAlign: "center", fontSize: "14pt", fontWeight: "bold", width: "50%" }}>{s.price1}</div>
                      <div style={{ paddingTop: 20, paddingBottom: 20, fontSize: "14pt", fontWeight: "bold", width: "50%", textAlign: "center" }}>{s.price2}</div>
                    </div>
                    <div style={{ fontSize: "12pt", fontStyle: "italic", }}>
                      {s["dsc_" + language]}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>

        <div id="bemutatkozas" style={{ paddingTop: 30, background: "#333" }} className='fullpage'>
          <div style={{ marginTop: 55 }}><span style={{ color: "#333" }}>_</span></div>
          <h2><span>{i18n[language].aboutus}</span></h2>
          <div className="container">
            <div className="image">
              <img src="https://menstage.hu/wp-content/uploads/2021/09/15B3A374-873F-434F-A866-F30CB6CE6F2F.jpeg" />
            </div>
            <div className="text">
              <div dangerouslySetInnerHTML={{ __html: i18n[language].aboutusdsc }}></div>
            </div>
          </div>
        </div>
        <div id="galery" style={{ paddingTop: 30 }} className=''>
          <div style={{ marginTop: 65 }}><span style={{ color: "#333" }}>_</span></div>
          <h2><span>{i18n[language].gallery}</span></h2>
          <div className='galery'>
            {
              galery.map((barber, index) => {
                return (
                  <div key={"gal" + index} className='galery-item'>
                    <img src={barber.image} />
                  </div>
                )
              })
            }
          </div>
        </div>
        <div id="foglalasaim" className='fullpage'>
          <div style={{ marginTop: 55 }}><span style={{ color: "#333" }}>_</span></div>
          <h2><span>{i18n[language].reservedtime}</span></h2>

              {
                myapps.length == 0 ?
          (<div>
            <div>
            <p className='form'>
              <span>
                {i18n[language].email}
              </span>
              <input id="email" key="email" type="email" name="email" value={email} onChange={(event) => setEmail(event.target.value)} />
            </p>
            <p className='form' style={{ display: lostpw ? "none" : "block" }}>
              <span>
                {i18n[language].password}
              </span>
              <input id="password" key="password" type="password" name="password" value={password} onChange={(event) => setPassword(event.target.value)} />
            </p>
          </div>

          <p className='form' onClick={() => {
            
              if (email == "") {
                alert(i18n[language].emailerror);
              } else if (password.length < 6) {
                alert(i18n[language].pwderror);
              } else {
                fetch("https://menstage.hu/mapi/index.php/api/getmyapps?email=" + email + "&password=" + password
                ).then(res => res.json()).then(result => {
                  setMyapps(result)
                  if(result.length === 0) {
                    if (language == "hu") {
                      alert("Foglaljon időpontot!");
                    } else {
                      alert("There is no appointment registered for you!");
                    }
                  }
                }
                ).catch(res => {
                  alert("Az e-mail jelszó páros nem megfelelő!");
                });
              }
            
        }}>
          <button>{lostpw ? i18n[language].lostpw : i18n[language].getmyapps}</button>
        </p>
        </div>) :
          (<OwlCarousel items={1} responsive={{ 0: { items: 1 }, 800: { items: 3 }, 1200: { items: 5 } }} stagePadding={70} className="owl-theme" loop={false} rewind={true} margin={10} nav={true}>
          {
            myapps.map((app, index) => {
              var barber = barbers.find(item => { return item.id == app.barber })
              return (
                <div className='item barber'>
                  <img className={index == 0 ? "early" : ""} src={barber.image} />
                  <h4><span>{index == 0 && language == "en" ? "Earliest date" : barber.name}</span></h4>
                  <h3 style={{ color: "#fff !important" }}>{app.date + " " + app.time}</h3>
                  <p>{barber.phone}</p>
                </div>
              )
            })
          }
          </OwlCarousel>)
  }

        </div>
        <div id="kapcsolat" className='fullpage'>
          <div style={{ marginTop: 55 }}><span style={{ color: "#333" }}>_</span></div>
          <h2><span>{i18n[language].contact}</span></h2>
          <p style={{ color: "#fff", padding: "5px 10px" }}>{i18n[language].email}: menstagesalon@gmail.com</p>
          <p style={{ color: "#fff", padding: "5px 10px" }}>{i18n[language].address}: 4024 Debrecen, Csapó u. 13.</p>
          <p style={{ color: "#fff", padding: "5px 10px" }}>
            {i18n[language].openhour}: <br />{i18n[language].mondaytofriday}: 10:00 - 19:00<br />
            {i18n[language].onsaturday}: 10:00 - 18:00
          </p>
          <div style={{ padding: 0 }}>
            <p style={{ textAlign: "center" }}>
              <a target="_blank" href="https://www.facebook.com/menstage"><img style={{ width: 34, marginRight: 20 }} src="/img/facebook-app-symbol.png" /></a>
              <a target="_blank" href="https://www.instagram.com/menstagesalon/"><img style={{ width: 34 }} src="/img/instagram.png" /></a>
            </p>
          </div>
          <div style={{ padding: 0 }}>
            <p style={{ textAlign: "center" }}>
              <a style={{ color: "#fff", paddingRight: 10, textDecoration: "none" }} onClick={() => { setModal1(true) }}>ÁSZF</a>
              <a style={{ color: "#fff", paddingRight: 10, textDecoration: "none" }} onClick={() => { setModal2(true) }}>ADATVÉDELMI</a>
            </p>
          </div>
          <iframe style={{ width: "100%", height: "100%" }} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?q=Debrecen%2C%20Csap%C3%B3%20u.%2013%2C%204024&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" title="Debrecen, Csapó u. 13, 4024" aria-label="Debrecen, Csapó u. 13, 4024"></iframe>
        </div>

        <div className="euslogo">
          <a href="pdf/sajtokozlemeny_uj.pdf" download={true} target="_blank">
            <img src="img/infoblokk_kedv_final_CMYK_ ESZA.png" alt="" />
          </a>
        </div>

        <div className='modal1' style={{ display: modal1 ? "block" : "none" }}>
          <a style={{ position: "absolute", top: 10, right: 10, color: "#fff" }}
            onClick={() => setModal1(false)}
          >X</a>
          <h1 style={{ padding: 10, color: "#fff" }}>Általános Szerződési Feltételek</h1>
          <p style={{ padding: 10, color: "#fff" }}>
            Az Általános Felhasználási Feltételek hatálya, alapelvek

            A jelen Általános Felhasználási Feltételek (a továbbiakban: a „Felhasználási Feltételek”) határozzák meg a Menstage Kft. (CÍM, CÉGJEGYZÉK SZÁM, a továbbiakban Szolgáltató) kizárólagos tulajdonában álló és a https://www.menstage.hu/elnevezésű weboldal (a továbbiakban: a „Weboldal”) felhasználásának a feltételeit.

            Felhasználónak (a továbbiakban: a „Felhasználó”) minősül minden olyan nagykorú természetes vagy jogi személyiséggel rendelkező gazdasági társaság vagy egyéb szervezet, aki a Weboldalat felkeresi, azon keresztül időpontot foglal bármely szolgáltatásra, vagy feliratkozik a Weboldal hírlevelére. Kiskorú személyek nem minősülnek felhasználónak.

            A Weboldal által nyújtott bármely szolgáltatás igénybevételével, hírlevélre való feliratkozással a Felhasználó magára nézve kötelezőnek fogadja el a jelen Felhasználási Feltételeket. A Felhasználási Feltételek ily módon való elfogadása egyben azt is jelenti, hogy a Felhasználó kötelezettséget vállal arra, hogy a jelen Felhasználási Feltételek változásait nyomon követi. Amennyiben a Felhasználó a jelen Felhasználási Feltételeket nem fogadja el, úgy nem jogosult a Weboldal használatára, illetve a Weboldal által kínált szolgáltatások igénybevételére. A Weboldal tulajdonosa és/vagy működtetője jogosult a jelen Felhasználási Feltételeket bármikor egyoldalúan módosítani. A módosított Felhasználási Feltételek hatályba lépésének napja az a nap, amelyen a módosított Felhasználási Feltételek publikálásra kerültek a Weboldalon. A Weboldal üzemeltetői mind a jelen Felhasználási Feltételekben, mind a Weboldalon „Menstage” néven is szerepelhetnek.

            A Felhasználó köteles a hozzá köthető, valós adatokat megadni. A Menstage Kft., amennyiben tudomására jut, hogy a Felhasználó által megadott adatok köre nem valós elemeket (is) tartalmaz, jogosult a Felhasználó szolgáltatáshoz való hozzáférését részlegesen, vagy teljes egészében korlátozni.

            Alapfogalmak

            Üzemeltető: Menstage Kft.

            Időpont foglalás: időpont foglalása a Weboldalon keresztül

            Szolgáltatás: Hajvágás, borotválás, valamint a Weboldalon keresztül a tranzakciók lehetőségének biztosítása. Egy konkrét időponthoz, dátumhoz és helyszínhez köthető Menstage hajvágás vagy borotválás.

            Weboldal: https://menstage.hu/ és azoknak minden microoldala

            A Menstage Kft. működése

            A Weboldalon több időpont megtalálható, ezeket a Menstage határozza meg, Felhasználó a megadott lehetőségek közül választ időpont, helyszín és elérhetőség alapján.

            Változó tényezők: A szolgáltatás időpontja, a szolgáltatást végző borbély személye kivételes esetben (pl. betegség, szabadság) változhat.

            A Menstage Kft. mindent megtesz, hogy a változások minél kevésbé érintsék a szolgáltatás pontos és minőségi teljesítését.

            Időkeret: A Menstage Kft. törekszik arra, hogy az előre meghatározott időpontban pontosan, az előre meghatározott időkeret/időtartam alatt teljesítse a szolgáltatást, azonban az időkeret esetenként változhat.

            Lemondás: A Felhasználó a szolgáltatást megelőzően 1 órával jogosult a díjmentes lemondásra.

            Készpénzes fizetés: A megrendelés oldalon kiválasztható a készpénzes fizetés. Ebben az esetben a Felhasználó a szolgáltatás helyszínén és időpontjában, készpénzzel fizet.

            Reklamáció: A távozást követően reklamációt nem fogadunk el.

            Viselkedési normák

            A Menstage Kft. szolgáltatásainak igénybevételekor, és/vagy Weboldalon elfogadott viselkedés magában foglalja, hogy Felhasználó más felhasználókat faji, nemi, vallási, identitásbeli hovatartozásukban és emberi mivoltukban tiszteli, sem szavakkal, sem tettekkel nem sérti szándékosan vagy szándékán kívül.

            Vásárlás

            Jegyvásárlás, lemondás

            Az időpont foglalást a Weboldalon a Program naptár gombra kattintva lehet indítani. Miután felhasználó kiválasztotta a helyszínt, napot, borbélyt, szolgáltatást és időpontot, az adatok megadása után a lefoglalom gomb megnyomásával indíthatja el a foglalás folyamatát.
            Az időpont foglalás véglegesítéséhez a Felhasználónak meg kell adnia a következő adatokat:
            Név (vezeték, keresztnév, cégnév)
            E-mail cím
            Telefonszám
            A szolgáltatás igénybevétele készpénzes fizetéssel lehetséges.
            A sikeres időpont foglalásról a Szolgáltató a Felhasználó által megadott e-mail címre küldött elektronikus levélben értesíti Felhasználót. A levél tartalmazza:
            A szolgáltatás időpontját
            A szolgáltatás helyszínét
            A kiválasztott borbélyt
            A foglalási nevet
            Lemondás: Felhasználónak legkésőbb a szolgáltatás időpontja előtt 1 órával jelezni kell a +36 1 6144731 telefonszámon, vagy a visszaigazoló emailben a LEMONDOM linkre kattintva amennyiben nem tud részt venni a szolgáltatások. Egyéb (Facebookon, más e-mailen, stb. történő) lemondást nem áll módunkban elfogadni. Ha Felhasználó az Esemény kezdőpontja előtt 1 órával nem jelzi lemondási szándékát, úgy azt nem áll módunkban elfogadni.
            Foglalás módosítása: Foglalási értéket, a foglalást követő és/vagy megelőző időszakban történő promóciós ajánlat tárgyaként megfogalmazott kedvezmény mértékével utólagosan csökkenteni nem lehet.
            Fénykép készítés

            A foglalás véglegesítésével, továbbá a szolgáltatáson való részvétellel felhasználó elfogadja, hogy a szolgáltatás helyszínén fénykép készülhet róla, melyet a Menstage Kft. korlátozás nélkül felhasználhat.

            Promóciók

            A Menstage Kft-nek joga van kedvezményeket adni a szolgáltatás eredeti árából. Ezen kedvezmények feltételeit egyoldalúan megváltoztathatja.
            A Menstage Kft. érdekkörét érintően Harmadik Fél is nyújthat kedvezményeket külön megállapodás alapján (pl. kuponakciók stb.), ez esetben azonban a Menstage Shop nem vállal felelősséget az adott partner ÁSzF-e és Adatkezelése iránt.
            Hírlevél

            A Weboldal használatának során Szolgáltató lehetőséget nyújt Hírlevélre történő feliratkozásra a Menstage Kft. által nyújtott szolgáltatásokkal kapcsolatos információkról, lehetőségekről, hírekről, akciókról, újdonságokról történő folyamatos tájékoztatás biztosítása érdekében.
            A Hírlevél-szolgáltatás igénybevétele illetőleg az ehhez kapcsolódó adatszolgáltatás minden esetben önkéntes.
            A Szolgáltató a Felhasználó által megadott adatokról nyilvántartást vezet. A nyilvántartásban rögzített adatok harmadik fél számára kizárólag az érintett Felhasználó előzetes hozzájárulásával adhatók át.
            A Felhasználó az adatainak megadásával felhatalmazza a Szolgáltatót, hogy a hozzájárulása visszavonásáig ezen adatokat az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény, valamint az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló 2001. évi CVIII. tv. rendelkezéseinek megfelelően kezelje.
            A Felhasználó a Hírlevél-szolgáltatásról korlátozás és indokolás nélkül bármikor, ingyenesen leiratkozhat. Ebben az esetben a Szolgáltató az általa vezetett nyilvántartásból haladéktalanul törli a Felhasználó adatait. A Felhasználó a visszavonó nyilatkozatot megteheti a Hírlevélben található leiratkozó linkre kattintva.
            Közösségi oldalak, marketing

            A Weboldalt lehetőség van a Felhasználó Facebook, Pinterest, YouTube vagy Instagram fiókjával összekötni. Amennyiben Felhasználó él ezzel a lehetőséggel, úgy részese lesz a Menstage közösségének az adott külső oldalon is, így felkerülhetnek az Esemény dokumentált anyagai (pl. fényképek) a közösségi oldalakra is, ahol Felhasználó beazonosítható lesz. Ismerősei, barátai látni fogják, hogy a Menstage közösség része.

            A Menstage Kft. szolgáltatásának időtartama alatt kép- és hangfelvétel készülhet, melyet Menstage marketing célokra használhat. Felhasználó a foglalás véglegesítésével, illetve a szolgáltatáson való részvétellel hozzájárul, hogy a róla készült kép- és hangfelvételeket a Szolgáltató pr- és marketingtevékenységében felhasználja.

            Felelősség

            A Menstage Kft. semmilyen körülmények között nem garantálja, hogy a szolgáltatás (ideértve a Weboldalt és minden hozzárendelt tartalmat, valamint az Eseményt) zavartalanul és hiba nélkül fog működni.

            A Menstage Kft. nem vállal felelősséget vagy kötelezettséget az alábbiakra:

            Hibák vagy hiányosságok a Weboldalon vagy a szolgáltatás igénybevételekor
            A szolgáltatás igénybevételekor vagy a Weboldalon bármely meghibásodás vagy a weboldal használatának megszakítása
            A Weboldalon lévő tartalmak (pl. időpontok) elérhetősége
            A szolgáltatás igénybevételét követően, a távozás utáni reklamációkért
            Irányadó jog, felügyeleti szervek

            A Szolgáltató és Felhasználó közötti jogviszonyra a magyar jog irányadó. A felek esetleges jogvita esetén, hatáskörtől függően kikötik a Szolgáltató székhelye szerinti, hatáskörrel rendelkező bíróság kizárólagos illetékességét.

            A Szolgáltatással kapcsolatos fogyasztói panasszal a Felhasználó az illetékes fogyasztóvédelmi hatóság, elektronikus hirdetésekkel kapcsolatosan a hírközlési hatóság eljárását kezdeményezheti.

            A Nemzeti Fogyasztóvédelmi Hatóság elérhetősége: 1364 Bp., Pf. 144; fogyved_kmf_budapest@nfh.hu

            A Nemzeti Média és Hírközlési Hatóság Hivatalának elérhetőségei: levélcím, 1525 Budapest, Pf., 75; info@nmhh.hu

            Az Adatvédelmi Szabályzat eléréséhez kattints ide.

            Bármilyen kérdés vagy panasz esetén keress minket: {email}

            Biztonságos fizetés bankkártyával:

            Tudomásul veszem, hogy a Menstage Kft. (Székhely: CÍM) adatkezelő által a www.menstage.hu felhasználói adatbázisában tárolt alábbi személyes adataim átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó részére. Az adatkezelő által továbbított adatok köre az alábbi: e-mail cím.
          </p>
        </div>

        <div className='modal2' style={{ background: "#3339", display: modal3 ? "block" : "none", overflow: "hidden", height: "100%", zIndex: 1000 }}>
          <a style={{ cursor: "pointer", position: "absolute", top: 10, right: 10, color: "#fff", fontSize: 20, fontWeight: "bold" }}
            onClick={() => setModal3(false)}>&#x58;</a>
          <div style={{ background: "#333", width: "70vw", margin: "auto" }}>
            <h1 style={{ padding: 10, color: "#fff", textAlign: "center" }}>Árváltozás!</h1>
            <p style={{ padding: 10, color: "#fff" }}>December 1-től új árrak várunk titeket.<br />Köszönjük a megértéseteket.</p>
          </div>
        </div>

        <div className='modal2' style={{ display: modal2 ? "block" : "none" }}>
          <a style={{ position: "absolute", top: 10, right: 10, color: "#fff" }}
            onClick={() => setModal2(false)}>X</a>
          <h1 style={{ padding: 10, color: "#fff" }}>Adatvédelmi nyilatkozat</h1>
          <p style={{ padding: 10, color: "#fff" }}>
            A SZABÁLYZAT HATÁLYA, ALAPELVEK

            Jelen szabályzat a Menstage Kft. (Székhely: CÍM adószám: ADÓSZÁM, a továbbiakban: a „Szolgáltató”) által meghirdetett borbély szolgáltatáshoz kapcsolódó Adatkezelési Szabályzata (a továbbiakban: Szabályzat).

            A Szolgáltató adatkezelő, aki jelen adatkezelése nyilvántartásba vételét kezdeményezte a Nemzeti Adatvédelmi és Információszabadság Hatóságnál.

            Jelen Szabályzat hatálya kiterjed az adatkezelőre, annak munkavállalóira és szerződött partnereire, valamint a borbély szolgáltatást igénybe vevőkre.

            A Menstage Kft. a mindenkori hatályos magyar jogszabályoknak és az európai uniós elvárásoknak megfelelően, tisztességesen és jogszerűen kezeli az Ön adatait.

            A Menstage Kft. elérhetősége:

            Székhely:
            Levelezési cím:
            Adószám:
            Cégjegyzékszám:
            Bank neve:
            Számlaszám:
            Email:


            A Menstage Kft. adatkezelése a 2011. évi CXII. törvény (Info tv.) rendelkezései szerint mindig az érintett önkéntes hozzájárulásán alapul, az adatokat illetéktelen személyek részére nem továbbítjuk, és nem hozzuk nyilvánosságra.

            Adatkezelési nyilvántartási száma: NAIH-130913/2017.

            Az adatkezelési szabályzat folyamatosan elérhető az alábbi címen:

            https://www.menstage.hu/

            A szabályzat esetleges módosításait folyamatosan jelezzük.

            A Menstage Kft. az alábbiakban ismerteti adatkezelési elveit, bemutatja azokat az elvárásokat, melyeket saját magával, mint adatkezelővel szemben megfogalmazott, és betart. Adatkezelési alapelvei összhangban vannak az adatvédelemmel kapcsolatos hatályos jogszabályokkal, így különösen az alábbiakkal:

            – 2011. évi CXII. törvény – az információs önrendelkezési jogról és az információszabadságról (Infotv.);

            – 1995. évi CXIX. törvény – a kutatás és a közvetlen üzletszerzés célját szolgáló név- és lakcímadatok kezeléséről (Katv.);

            – 2000. évi C. törvény – a számvitelről;

            – 2001. évi CVIII. törvény – az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről (Eker. tv.);

            – 2003. évi C. törvény – az elektronikus hírközlésről (Eht.);

            – 2008. évi XLVIII. törvény – a gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól (Grt.).

            1. Definíciók

            1.1. Érintett: bármely meghatározott, személyes adat alapján azonosított vagy – közvetlenül vagy közvetve – azonosítható természetes személy;

            1.2. Személyes adat: az érintettel kapcsolatba hozható adat – különösen az érintett neve, azonosító jele, valamint egy vagy több fizikai, fiziológiai, mentális, gazdasági, kulturális vagy szociális azonosságára jellemző ismeret –, valamint az abból levonható, az érintettre vonatkozó következtetés;

            1.3. Hozzájárulás: az érintett kívánságának önkéntes és határozott kinyilvánítása, amely megfelelő tájékoztatáson alapul, és amellyel félreérthetetlen beleegyezését adja a rá vonatkozó személyes adatok – teljes körű vagy egyes műveletekre kiterjedő – kezeléséhez;

            1.4. Tiltakozás: az érintett nyilatkozata, amellyel személyes adatainak kezelését kifogásolja, és az adatkezelés megszüntetését, illetve a kezelt adatok törlését kéri;

            1.5. Adatkezelő: az a természetes vagy jogi személy, illetve jogi személyiséggel nem rendelkező szervezet, aki, vagy amely önállóan vagy másokkal együtt az adatok kezelésének célját meghatározza, az adatkezelésre (beleértve a felhasznált eszközt) vonatkozó döntéseket meghozza és végrehajtja, vagy az általa megbízott adatfeldolgozóval végrehajtatja;

            1.6. Adatkezelés: az alkalmazott eljárástól függetlenül az adatokon végzett bármely művelet vagy a műveletek összessége, így különösen gyűjtése, felvétele, rögzítése, rendszerezése, tárolása, megváltoztatása, felhasználása, lekérdezése, továbbítása, nyilvánosságra hozatala, összehangolása vagy összekapcsolása, zárolása, törlése és megsemmisítése, valamint az adatok további felhasználásának megakadályozása.

            1.7. Adattovábbítás: az adat meghatározott harmadik személy számára történő hozzáférhetővé tétele;

            1.8. Nyilvánosságra hozatal: az adat bárki számára történő hozzáférhetővé tétele;

            1.9. Adattörlés: az adatok felismerhetetlenné tétele oly módon, hogy a helyreállításuk többé nem lehetséges;

            1.10. Adatmegjelölés: az adat azonosító jelzéssel ellátása annak megkülönböztetése céljából;

            1.11. Adatzárolás: az adat azonosító jelzéssel ellátása további kezelésének végleges vagy meghatározott időre történő korlátozása céljából;

            1.12. Adatmegsemmisítés: az adatokat tartalmazó adathordozó teljes fizikai megsemmisítése;

            1.13. Adatfeldolgozás: az adatkezelési műveletekhez kapcsolódó technikai feladatok elvégzése, függetlenül a műveletek végrehajtásához alkalmazott módszertől és eszköztől, valamint az alkalmazás helyétől, feltéve hogy a technikai feladatot az adatokon végzik;

            1.14. Adatfeldolgozó: az a természetes vagy jogi személy, illetve jogi személyiséggel nem rendelkező szervezet, aki vagy amely az adatkezelővel kötött szerződése alapján – beleértve a jogszabály rendelkezése alapján történő szerződéskötést is – az adatok feldolgozását végzi;

            1.15. Harmadik személy: olyan természetes vagy jogi személy, illetve jogi személyiséggel nem rendelkező szervezet, aki vagy amely nem azonos az érintettel, az adatkezelővel vagy az adatfeldolgozóval;

            1.16. Harmadik ország: minden olyan állam, amely nem EGT-állam.

            2. Alapelvek a Menstage Kft. adatkezelése során

            Személyes adat akkor kezelhető, ha

            ahhoz az érintett hozzájárul, vagy
            azt törvény vagy – törvény felhatalmazása alapján, az abban meghatározott körben – helyi önkormányzat rendelete közérdeken alapuló célból elrendeli (kötelező adatkezelés).
            Személyes adat akkor is kezelhető, ha az érintett hozzájárulásának beszerzése lehetetlen vagy aránytalan költséggel járna, és a személyes adat kezelése az adatkezelőre vonatkozó jogi kötelezettség teljesítése céljából szükséges, vagy az adatkezelő vagy harmadik személy jogos érdekének érvényesítése céljából szükséges, és ezen érdek érvényesítése a személyes adatok védelméhez fűződő jog korlátozásával arányban áll.

            Ha a személyes adat felvételére az érintett hozzájárulásával került sor, az adatkezelő a felvett adatokat törvény eltérő rendelkezésének hiányában

            a rá vonatkozó jogi kötelezettség teljesítése céljából, vagy
            az adatkezelő vagy harmadik személy jogos érdekének érvényesítése céljából, ha ezen érdek érvényesítése a személyes adatok védelméhez fűződő jog korlátozásával arányban áll további külön hozzájárulás nélkül, valamint az érintett hozzájárulásának visszavonását követően is kezelheti.
            Személyes adatot kezelni csak meghatározott célból, jog gyakorlása és kötelezettség teljesítése érdekében lehet. Az adatkezelésnek minden szakaszában meg kell felelnie e célnak, továbbá az adatok felvételének és kezelésének tisztességesnek kell lennie.

            Csak olyan személyes adat kezelhető, amely az adatkezelés céljának megvalósulásához elengedhetetlen, a cél elérésére alkalmas, csak a cél megvalósulásához szükséges mértékben és ideig.

            Személyes adat csak megfelelő tájékoztatáson alapuló beleegyezéssel kezelhető.

            Az érintettel az adatkezelés megkezdése előtt közölni kell, hogy az adatkezelés hozzájáruláson alapul vagy kötelező. Az érintettet – egyértelműen, közérthetően és részletesen – tájékoztatni kell az adatai kezelésével kapcsolatos minden tényről, így különösen az adatkezelés céljáról és jogalapjáról, az adatkezelésre és az adatfeldolgozásra jogosult személyéről, az adatkezelés időtartamáról, arról, ha az érintett személyes adatait az adatkezelő az érintett hozzájárulásával és az adatkezelőre vonatkozó jogi kötelezettség teljesítése vagy harmadik személy jogos érdekének érvényesítése céljából kezeli, illetve arról, hogy kik ismerhetik meg az adatokat. A tájékoztatásnak ki kell terjednie az érintett adatkezeléssel kapcsolatos jogaira és jogorvoslati lehetőségeire is.

            Az adatkezelés során biztosítani kell az adatok pontosságát, teljességét, naprakészségét, valamint azt, hogy az érintettet csak az adatkezelés céljához szükséges ideig lehessen azonosítani.

            Személyes adat harmadik országban adatkezelést folytató adatkezelő vagy adatfeldolgozást végző adatfeldolgozó részére akkor továbbítható, ha ahhoz az érintett kifejezetten hozzájárult, vagy az adatkezelésnek az előzőekben előírt feltételei teljesülnek, és a harmadik országban az átadott adatok kezelése, valamint feldolgozása során biztosított a személyes adatok megfelelő szintű védelme. Az EGT-államokba irányuló adattovábbítást úgy kell tekinteni, mintha Magyarország területén belüli adattovábbításra kerülne sor.

            3. A személyes adatok köre, az adatkezelés célja, jogcíme és időtartama

            A Menstage Kft. tevékenységének adatkezelései önkéntes hozzájáruláson alapulnak. Bizonyos esetekben azonban a megadott adatok egy körének kezelését, tárolását, továbbítását jogszabályok teszik kötelezővé, melyről külön értesítjük közönségünket.

            Felhívjuk a Menstage Kft. részére a személyes adataikat megadók figyelmét, hogy amennyiben nem saját személyes adataikat adják meg, az adatközlő kötelessége az érintett hozzájárulásának beszerzése.

            3.1. A https://www.menstage.hu/ honlap látogatóinak adatai

            Az adatkezelés célja: a Menstage Kft. borbély szolgáltatásainak igénybe vétele a https://www.menstage.hu/ weboldalon történő bejelentkezéssel ajándékutalvány vásárlása, ajándékakciók lebonyolítása, hírlevelek és információk küldése, kapcsolattartás.

            A cél megvalósításához kötötten az adatkezelő rögzíti a honlap felhasználójának személyes adatait (többek között: név, email cím, telefonszám), rögzíti az egyedi azonosítóhoz társított felhasználói profil adatait, az igénybe vett szolgáltatásokat vagy egyéb aktivitásoknál (pl. kérdőívek önkéntes kitöltésekor) felvett adatokat.

            Az adatkezelés jogalapja: az érintett hozzájárulása.

            A kezelt adatok köre: Név, mobil telefonszám, e-mail cím

            Az adatkezelés időtartama: A Menstage működése, az indulástól a visszavonásig illetve az adatgazda törlési kérelmére azonnali törléssel lezárul.

            4. Egyéb adatkezelések

            Az ügyfelek a bíróság, az ügyész, a nyomozó hatóság, szabálysértési hatóság, a közigazgatási hatóság, a Nemzeti Adatvédelmi és Információszabadság Hatóság, illetőleg jogszabály felhatalmazása alapján más szervek tájékoztatás adása, adatok közlése, átadása, illetőleg iratok rendelkezésre bocsátása végett megkereshetik az adatkezelőt.

            A Menstage Kft. a hatóságok részére – amennyiben a hatóság a pontos célt és az adatok körét megjelölte – személyes adatot csak annyit és olyan mértékben ad ki, amely a megkeresés céljának megvalósításához elengedhetetlenül szükséges.

            5. Adatbiztonság

            Az adatok tárolása a Menstage Kft. (CÍM). szerverén történik.

            A Menstage Kft. a személyes adatok kezeléséhez a szolgáltatás nyújtása során alkalmazott informatikai eszközöket úgy választja meg és üzemelteti, hogy a kezelt adat:

            az arra feljogosítottak számára hozzáférhető (rendelkezésre állás);
            hitelessége és hitelesítése biztosított (adatkezelés hitelessége);
            változatlansága igazolható (adatintegritás);
            a jogosulatlan hozzáférés ellen védett (adat bizalmassága) legyen.
            A Menstage Kft. az adatokat megfelelő intézkedésekkel védi különösen a jogosulatlan hozzáférés, megváltoztatás, továbbítás, nyilvánosságra hozatal, törlés vagy megsemmisítés, valamint a véletlen megsemmisülés, sérülés, továbbá az alkalmazott technika megváltozásából fakadó hozzáférhetetlenné válás ellen.

            A Menstage Kft. a különböző nyilvántartásaiban elektronikusan kezelt adatállományok védelme érdekében megfelelő technikai megoldással biztosítja, hogy a tárolt adatok – kivéve, ha azt törvény lehetővé teszi – közvetlenül ne legyenek összekapcsolhatók és az érintetthez rendelhetők.

            A Menstage Kft. a technika mindenkori fejlettségére tekintettel olyan műszaki, szervezési és szervezeti intézkedésekkel gondoskodik az adatkezelés biztonságának védelméről, amely az adatkezeléssel kapcsolatban jelentkező kockázatoknak megfelelő védelmi szintet nyújt.

            A Menstage Kft. az adatkezelés során megőrzi

            a titkosságot: megvédi az információt, hogy csak az férhessen hozzá, aki erre jogosult;
            a sértetlenséget: megvédi az információnak és a feldolgozás módszerének a pontosságát és teljességét;
            a rendelkezésre állást: gondoskodik arról, hogy amikor a jogosult használónak szüksége van rá, valóban hozzá tudjon férni a kívánt információhoz, és rendelkezésre álljanak az ezzel kapcsolatos eszközök.
            A Menstage Kft. és partnereinek informatikai rendszere és hálózata egyaránt védett a számítógéppel támogatott csalás, kémkedés, szabotázs, vandalizmus, tűz és árvíz, továbbá a számítógépvírusok, a számítógépes betörések és a szolgálatmegtagadásra vezető támadások ellen. Az üzemeltető a biztonságról szerverszintű és alkalmazásszintű védelmi eljárásokkal gondoskodik.

            Tájékoztatjuk a felhasználókat, hogy az interneten továbbított elektronikus üzenetek, protokolltól (e-mail, web, ftp, stb.) függetlenül sérülékenyek az olyan hálózati fenyegetésekkel szemben, amelyek tisztességtelen tevékenységre, szerződés vitatására, vagy az információ felfedésére, módosítására vezetnek. Az ilyen fenyegetésektől megvédendő a szolgáltató megtesz minden tőle elvárható óvintézkedést. A rendszereket megfigyeli annak érdekében, hogy minden biztonsági eltérést rögzíthessen, és bizonyítékkal szolgálhasson minden biztonsági esemény esetében. A rendszermegfigyelés ezen kívül lehetővé teszi az alkalmazott óvintézkedések hatékonyságának ellenőrzését is.

            5. Jogorvoslat

            A felhasználó bármikor tájékoztatást kérhet személyes adatai kezeléséről, kérheti azok helyesbítését, illetve – a jogszabályban elrendelt adatkezelések kivételével – törlését a downtownborbely@gmail.com. e-mail címre küldött elektronikus levélben.


          </p>
        </div>

      </div>
    );
  }
}

export default App;
